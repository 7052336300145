<template>
  <section class="conOfProductItem pt-5 h-100">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="sectionHeaderWithSub">
            {{productEnums[$route.params.id]}}
            <router-link class="eachMapLink" :to="{ name: 'settingProducts' }">
              System Settings
            </router-link>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          lg="3"
          md="4"
          sm="6"
          cols="12"
          v-for="product in productFiltered"
          :key="product.id"
        >
          <router-link
            :to="{
              name: product.routerName,
              params: { id: $route.params.id },
            }"
          >
            <v-card
              class="mx-auto pa-5 fill-height conCard"
              max-width="100%"
              elevation="1"
              tile
            >
              <div
                class="
                  fill-height
                  conOfCard
                  d-flex
                  align-center
                  justify-space-around
                "
              >
                <div class="conImg">
                  <img
                    :src="require(`@/assets/img/productIcons/${product.icon}`)"
                    alt=""
                  />
                </div>
                <div class="conTxt">
                  {{ product.name }}
                </div>
              </div>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import productEnum from "../../mixins/productEnums";
export default {
  mixins: [productEnum],
  data: () => ({
    products: [
      {
        id: 1,
        name: "INDICATION",
        icon: "indication.png",
        routerName: "settingIndication",
      },
      {
        id: 2,
        name: "INFUSION CENTER",
        icon: "INFUSIONCENTER.png",
        routerName: "settingInfectionCenter",
      },
      {
        id: 3,
        name: "OTHER SECTORS",
        icon: "OTHERSECTORS.png",
        routerName: "settingOtherSector",
      },
      { id: 4, name: "HCP", icon: "HCP.png", routerName: "settingHCP" },
      { id: 5, name: "PS", icon: "PS.png", routerName: "settingps" },
      {
        id: 6,
        name: "TREATMENT LINE",
        icon: "TREATMENTLINE.png",
        routerName: "settingTreatmentLine",
      },
      { id: 7, name: "LRFP", icon: "LRFP.png", routerName: "settingLRFP" },
    ],
    productFiltered: null,
    itemsRemoved: {
      1: [1],
      2: [2, 6],
      3: [2, 6],
      4: [1, 2],
      5: [1, 2],
      6: [1, 2],
    },
  }),

  methods: {
    filterProducts(id) {
      let filteredArrayOfProduct = this.itemsRemoved[id];
      this.productFiltered = this.products.filter(function(item) {
          return filteredArrayOfProduct.indexOf(item.id) == -1 ;
      });
    },
  },
  created() {
    this.filterProducts(this.$route.params.id);
  },
};
</script>
<style lang="scss" scoped>
@import "./_productItem.scss";
</style>
